import React from 'react'
import {useState} from 'react';

function About() {

    return (
        <div className={'py-20 text-center'}>
           <h1>About Page Here</h1>
        </div>
    )
}

export default About