import React from 'react'
import {Link, Outlet} from "react-router-dom";
import logo from '../assets/img/logo.png'
import useComponentVisible from "../hooks/useComponentVisible";

function Home() {
    const {
        ref,
        isComponentVisible,
        setIsComponentVisible
    } = useComponentVisible(false);

    return (
        <div className={''}>
            <div ref={ref} className={'sticky top-0 z-50 font-semibold text-sm text-slate-900 ring-1 ring-slate-900/10'}>
                <div className={'flex bg-gradient-to-r bg-slate-50/90 backdrop-blur-sm  shadow-md shadow-slate-200 p-3'}>
                    <div className={'from-inherit align-middle'}>
                        <Link to={"/"}  className={'flex'}><img src={logo} width={100} alt={'CLC'}/> <span className={'my-auto pl-2 text-2xl md:text-3xl font-clc '}>Ceylon Ladies' Campus</span></Link>
                    </div>
                    <div className={'grow'}></div>
                    <div className={'hidden md:flex'}>
                        <nav className="text-sm leading-6 font-semibold text-slate-700 dark:text-slate-200">
                            <ul className="flex space-x-8 px-8 h-full">
                                <li className={'my-auto primary-fore-color'}><Link to={"/"}>Home</Link></li>
                            </ul>
                        </nav>
                        <div className={'my-auto mr-2'}>
                            <Link to={"https://smart.clcsl.edu.lk/"} className={'py-2 px-3 bg-red-600 text-white text-sm font-semibold rounded-md shadow-lg shadow-blue-500/50 focus:outline-none'}>Sign In</Link>
                        </div>
                        <div className={'my-auto'}>
                            <Link to={"/online-application"} className={'py-2 px-3 bg-blue-700 text-white text-sm font-semibold rounded-md shadow-lg shadow-blue-500/50 focus:outline-none'}>JOIN NOW!</Link>
                        </div>
                    </div>
                    {!isComponentVisible ? (
                        <div className={'flex md:hidden animate-fadeIn pr-4'}>
                            <div className={'icon-bars my-auto'} onClick={() => setIsComponentVisible(true)}></div>
                        </div>
                    ) : (
                        <div className={'flex md:hidden animate-fadeIn pr-4'}>
                            <div className={'my-auto'} onClick={() => setIsComponentVisible(false)}>X</div>
                        </div>
                    )}
                </div>
                <div className={(isComponentVisible ? 'animate-slide' : 'animate-slideOut hidden') + ' absolute top-20 z-50 right-0 bg-slate-50 w-full'}>

                    <nav className="px-3 py-3 text-sm leading-6 font-semibold text-slate-700 dark:text-slate-200">
                        <ul className="h-full w-full px-3 py-3 text-red-900">
                            {/*<li className={'my-auto'} onClick={() => setIsComponentVisible(false)}><Link to={"/"}>Home</Link></li>*/}
                            {/*<li className={'my-auto'}><Link to={"/online-application"}>Apply Now!</Link></li>*/}
                            {/*<li className={'my-auto'}><Link to={"/about"}>Contact</Link></li>*/}
                            {/*<li className={'my-auto'}><Link to={"/about"}>Blog</Link></li>*/}
                        </ul>
                        <div className={'flex justify-center pb-3'}>
                            <div className={'mr-2'} onClick={() => setIsComponentVisible(false)}>
                                <Link to={"/"} className={'py-2 px-3 bg-blue-600 text-white text-sm font-semibold rounded-md shadow-lg shadow-blue-500/50 focus:outline-none'}>Home</Link>
                            </div>
                            <div className={'mr-2'}>
                                <Link to={"https://smart.clcsl.edu.lk/"} className={'py-2 px-3 bg-red-600 text-white text-sm font-semibold rounded-md shadow-lg shadow-blue-500/50 focus:outline-none'}>Sign In</Link>
                            </div>
                            <div className={''}  onClick={() => setIsComponentVisible(false)}>
                                <Link to={"/online-application"} className={'py-2 px-3 bg-blue-700 text-white text-sm font-semibold rounded-md shadow-lg shadow-blue-500/50 focus:outline-none'}>JOIN NOW!</Link>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <Outlet />

            {/* Footer */}
            <div className={'bg-gray-900 text-white p-10'}>
                <div className={'from-inherit align-middle'}>
                    <div  className={'my-auto text-2xl md:text-3xl font-clc '}>Ceylon Ladies' Campus</div>
                    <div  className={''}>The best place for future leaders to begin their journey. We will provide the facilities for the girls to start their advanced level studies in a safe & peaceful environment.</div>
                    <div  className={'pt-10'}>168, Anguruwella Road, Ganithapura, Warakapola | +94764807648 | official.clcsl@gmail.com</div>
                    <div  className={'pt-10'}>© 2023 Ceylon Ladies' Campus </div>
                </div>
            </div>
        </div>
    )
}

export default Home
