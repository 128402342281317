import {motion} from "framer-motion";

function App(props) {
    const setShowHidden = () => {
        if (props.onHide) {
            props.onHide();
        }
    }

    return (
        <div>
            {props.show ?
                <motion.div
                    initial={{ opacity: 0, y: -300 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{opacity: 0}}
                    transition={{
                        duration: 1,
                        ease: [0, 0.71, 0.2, 1.01]
                    }}
                    className={'fixed top-0 w-screen h-screen z-50 bg-gray-300/25'}
                >
                <div className={'flex justify-center align-middle h-full w-full'} style={{alignItems: 'center'} }>
                    <div className={'absolute backdrop-blur-sm backdrop-opacity-10 w-screen h-screen'} onClick={setShowHidden}></div>
                    <div className={'absolute p-10 bg-white w-11/12 rounded-xl shadow-2xl backdrop-brightness-50 overflow-auto'} style={{maxHeight: 'calc(100vh - 150px)'}}>
                        {props.children}
                    </div>
                </div>
                </motion.div>
            :
                ''
            }
        </div>
    );
}

export default App;
